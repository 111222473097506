import ReactGA from 'react-ga4';

import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalFooter, ModalBody, Image, Text, Badge, Stack, Flex, Link, Icon, Box, Tooltip, IconButton, useColorModeValue, useToast } from '@chakra-ui/react';
import { MdStar, MdLanguage, MdAccessTime, MdLocationOn, MdDirections } from 'react-icons/md';
import { ExternalLinkIcon } from '@chakra-ui/icons';

import ShareOptions from '../common/ShareOptions';
import coffeePlaceholder from "../../assets/icons/coffee_thumbnail_placeholder.svg"
  
const ShopModal = ({ shop, isOpen, onClose }) => {
    const toast = useToast();

    const bg = useColorModeValue('gray.50', '#2c2c2c');

    const handleFavoritesClick = () => {
        ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: `Add to Favorites`,
          });

        toast({
            title: 'Coming soon - Add to Favorites',
            status: 'info',
            duration: 3000,
            isClosable: true,
        });
    };

    const handleDirectionsClick = (shop) => {
        ReactGA.event({
          category: 'Button',
          action: 'Click',
          label: `Get Directions - ${shop.name}`,
        });
    
        if (window !== undefined) {
          window.open(shop.google_maps_url, '_blank', 'noopener,noreferrer');
        }
    };
    
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior={'inside'}>
            <ModalOverlay backdropFilter='blur(10px)' />
            <ModalContent ml={4} mr={4} backgroundColor={bg} borderRadius={"20px"}>
                <ModalCloseButton backgroundColor={bg} borderRadius={'20px'}/>
                <Image src={`https://places.googleapis.com/v1/${shop.thumbnail}` || coffeePlaceholder} alt={shop.name} objectFit="cover" borderTopRadius={"20px"} w="100%" maxH='250px'/>
                <ModalHeader pb={0}>
                    <Flex>
                        <Badge colorScheme={shop.open_now ? 'green' : 'red'} mt={2}>{shop.open_now ? 'Open Now' : 'Closed'}</Badge>
                    </Flex>
                    {shop.name}
                </ModalHeader>
                <ModalBody>
                    <Flex direction="column">
                        <Stack spacing={2}>
                            <Flex alignItems="center">
                                <Icon as={MdStar} color="yellow.500" />
                                <Text ml={1} fontSize={{base:'xs', md:'sm'}}>{shop.rating ? `${shop.rating} (${shop.user_ratings_total} reviews)` : 'Rating not available'}</Text>
                            </Flex>
                            <Flex alignItems="center" mt="5px">
                                <Icon as={MdLanguage} mr="5px" />
                                    {shop.website ? (
                                    <Tooltip label={shop.website}>
                                        <Link href={shop.website} isExternal display="flex" alignItems="center" fontSize={{base:'xs', md:'sm'}}>
                                            Website <ExternalLinkIcon mx='2px' />
                                        </Link>
                                    </Tooltip>
                                    ) : (
                                    <Text fontSize={{base:'xs', md:'sm'}}>Website not available</Text>
                                    )}
                            </Flex>
                            <Flex alignItems="center" mt="5px">
                                <Icon as={MdAccessTime} mr="5px" color="text.secondary" />
                                <Text fontSize={{base:'xs', md:'sm'}}>Hours:</Text>
                            </Flex>
                            <Box ml="24px" display="flex" flexDirection="column">
                                {shop.opening_hours ? (
                                    shop.opening_hours.map((hours, index) => {
                                    let [day, ...timeParts] = hours.split(':'); // ["Monday", " 11", "30 AM – 8", "00 PM"]
                                    let time = timeParts.join(':'); // " 11:30 AM – 8:00 PM"
                                    return (
                                        <Text key={index} fontSize={{base:'xs', md:'sm'}}>
                                            <span style={{ minWidth: '80px', display: 'inline-block' }}>{day}</span>
                                            <span>{time}</span>
                                        </Text>
                                    )})
                                ) : (
                                    <Text fontSize={{base:'xs', md:'sm'}}>Opening hours not available</Text>
                                )}
                            </Box>
                            <Flex alignItems="center" mt="5px">
                                <Icon as={MdLocationOn} mr="5px" color="text.secondary" />
                                <Text fontSize={{base:'xs', md:'sm'}}>
                                    {shop.address}
                                </Text>
                            </Flex>
                        </Stack>
                    </Flex>
                </ModalBody>
                <ModalFooter>
                    <Flex justify="space-between" alignItems="center" w="100%">
                        <IconButton onClick={handleFavoritesClick} aria-label="add to favorites" borderRadius={"16px"} opacity="0.5">
                            <MdStar />
                        </IconButton> 

                        <ShareOptions url={shop.google_maps_url} name={shop.name} title="Check out this local coffee shop: " />

                        <Tooltip label="Directions" hasArrow placement='top'> 
                            <IconButton icon={ <MdDirections />} onClick={()=> {handleDirectionsClick(shop)}} aria-label="Directions" fontSize={{ base: "20", md: "25" }} borderRadius={"16px"} />                   
                        </Tooltip>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
  
  export default ShopModal;
  