import React from "react";
import ReactGA from 'react-ga4';

import { MenuButton, MenuList, Link, IconButton, Menu, MenuItem, HStack, Text, Tooltip } from "@chakra-ui/react";
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, XIcon, WhatsappIcon, TelegramIcon, TelegramShareButton} from "react-share";
import { FiShare2 } from 'react-icons/fi';
import { LiaSmsSolid } from "react-icons/lia";

const ShareOptions = ({ url, name, title }) => {
  const smsHref = `sms:?&body=${encodeURIComponent(title + " " + url)}`;
  // const bg = useColorModeValue('gray.50', '#2c2c2c');

  const handleMenuButtonClick = (event) => {
    event.stopPropagation(); // Prevent the click from bubbling up to the card
  };

  const trackShare = (event, platform) => {
    event.stopPropagation();
    ReactGA.event({
      category: 'Social Share',
      action: 'Share',
      label: platform + name,
    });
  };

  return (
    <Menu>
      <Tooltip label="Share shop" hasArrow placement='top'> 
        <MenuButton as={IconButton} aria-label="Options" icon={<FiShare2 />} borderRadius={"16px"}  onClick={handleMenuButtonClick}/>
      </Tooltip>
      <MenuList>
        <MenuItem as={WhatsappShareButton} url={url} title={title} onClick={(event) => {trackShare(event, 'Whatsapp')}}>
          <HStack p={2} spacing={2}>
            <WhatsappIcon size={24} round={true} />
            <Text>WhatsApp</Text>
          </HStack>
        </MenuItem>
        <MenuItem as={TelegramShareButton} url={url} title={title} onClick={(event) => {trackShare(event, 'Telegram')}}>
          <HStack p={2} spacing={2}>
            <TelegramIcon size={24} round={true} />
            <Text>Telegram</Text>
          </HStack>
        </MenuItem>
        <MenuItem as={FacebookShareButton} url={url} title={title} onClick={(event) => {trackShare(event, 'Facebook')}}>
          <HStack p={2} spacing={2}>
            <FacebookIcon size={24} round={true} />
            <Text>Facebook</Text>
          </HStack>
        </MenuItem>
        <MenuItem as={TwitterShareButton} url={url} title={title} onClick={(event) => {trackShare(event, 'X')}}>
          <HStack p={2} spacing={2}>
            <XIcon size={24} round={true} />
            <Text>X (Twitter)</Text>
          </HStack>
        </MenuItem>
        <MenuItem onClick={(event) => {trackShare(event, 'SMS')}} >
          <Link href={smsHref} isExternal _hover={{ textDecoration: "none" }}>
          <HStack>
            <LiaSmsSolid size={24}/>
            <Text>SMS</Text>
          </HStack>
          </Link>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ShareOptions;