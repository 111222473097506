import React, { useState, useContext } from "react";
import axios from "axios";
import debounce from 'lodash.debounce';

import {Input, InputGroup, InputLeftElement, Box, List, ListItem, useColorMode} from "@chakra-ui/react";
import { Search2Icon } from "@chakra-ui/icons";

import { CoffeeShopContext } from "../../context/CoffeeShopContext";

const SearchBar = ({ onPlaceSelected }) => {
  const { colorMode } = useColorMode();
  const { updateUserLocation } = useContext(CoffeeShopContext);
  const [places, setPlaces] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const fetchPlaces = debounce(async (query) => {
    // TODO: Move the API call to back-end server
    // const response = await fetch(`http://localhost:3001/geocode?query=${query}`);
    // // setPlaces(response.data.features);

    const params = {
      access_token: process.env.REACT_APP_MAPBOX_TOKEN,
      country: 'us',
      proximity :'ip',
      limit: 4,
      types:'address'
    };

    const response = await axios.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`,{params});
    setPlaces(response.data.features)
  }, 300);

  const handleInputChange = (e) => {
    const query = e.target.value;
    setInputValue(query);
    fetchPlaces(query);
  };

  const handleSelection = (place) => {
    setInputValue(place.place_name);
    setPlaces([]);
    onPlaceSelected(place.center);
    updateUserLocation({
      latitude: place.center[1],
      longitude: place.center[0],
    });
  };

  return (
    <Box width="100%" position="relative">
      <InputGroup>
        <InputLeftElement pointerEvents='none'>
          <Search2Icon />
        </InputLeftElement>
        <Input
          variant="outline" 
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Enter Address"
          borderRadius="20px"
          _placeholder={{ opacity: 0.4, color: 'inherit' }}
          bg={colorMode === 'dark' ? 'transparent' : 'gray.50'}
          borderColor={colorMode === 'dark' ? 'inherit' : 'transparent'}
          focusBorderColor={colorMode === 'dark' ? 'coffee.100' : 'transparent'}
        />
      </InputGroup>
      {places.length > 0 && (
        <List spacing={2} mt={2} p={2} bg="white" boxShadow="md" borderRadius="20px" position="absolute" width="full" zIndex="dropdown">
          {places.map((place) => (
            <ListItem key={place.id} cursor="pointer" color='black' _hover={{ bg: 'gray.100' }} onClick={() => handleSelection(place)} textAlign={"left"}>
              {place.place_name}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default SearchBar;

// <Downshift
    //   onChange={(selection) => {
    //     onPlaceSelected(selection.center);
    //     updateUserLocation({
    //       latitude: selection.center[1],
    //       longitude: selection.center[0],
    //     });
    //     setInputValue(selection.place_name)
    //   }}
    //   itemToString={item => (item ? item.place_name : "")}
    //  >
    //   {({getRootProps, getInputProps, getItemProps, getMenuProps, highlightedIndex, isOpen, selectedItem}) => (

    //     <div {...getRootProps({}, {suppressRefError: true})}>
    //       <Paper sx={{ display: "flex", alignItems: "center", maxWidth: {xs:375, sm:375, md: 375 }, margin: { xs: '0 auto', md: '0 auto' }, borderRadius: "20px", position: "relative"}}>
    //         <TextField 
    //           {...getInputProps({
    //             onChange: (e) => {
    //               setInputValue(e.target.value);
    //               fetchPlaces(e.target.value);
    //             },
    //             onFocus: () => {
    //               setInputValue("");
    //             },
    //             value:inputValue,
    //             placeholder: "Enter Address",
    //           })}
    //           sx={{
    //             flex: 1,
    //             '& input': {
    //                 fontSize: {
    //                   xs: '0.8rem',
    //                   sm: '1rem',
    //                   md: '1rem',
    //                   lg:'1rem'
    //             }},
                
    //             "& .MuiOutlinedInput-root": {
    //               borderRadius: "20px",
    //               '&.Mui-focused fieldset': {
    //                 borderColor: '#8B4513',
    //               }
    //             }
    //           }}
    //           InputProps={{ 
    //             endAdornment: (
    //               <InputAdornment position="end" >
    //                 <IconButton aria-label="search" sx={{color: "#6B4F2E",  fontSize: {xs: 'small', sm: 'medium', md: 'large'} }} size="small">
    //                   <SearchIcon />
    //                 </IconButton>
    //               </InputAdornment>
    //           )}}
    //         />
    //         <Paper {...getMenuProps()} 
    //           sx={{ 
    //             position: "absolute", 
    //             top: "100%", 
    //             left: 0, 
    //             maxWidth: "100%", 
    //             minWidth: "100%", 
    //             zIndex: 10, 
    //             overflow: "auto", 
    //             whiteSpace: "normal", // Allow text to wrap
    //             wordWrap: "break-word", // Ensure long words do not cause the element to expand horizontally
    //           }}>
    //           {isOpen
    //             ? places.map((place, index) => (
    //                 <MenuItem {...getItemProps({ item: place })} key={place.id} selected={highlightedIndex === index} component="div" sx={{fontSize: {
    //                   xs: '0.8em',  // Smaller font-size for smaller screens
    //                   md: '1em'   // Normal font-size for medium and larger screens
    //                 },
    //                 whiteSpace: "normal", wordWrap: "break-word"}}>
    //                   {place.place_name}
    //                 </MenuItem>
    //               ))
    //           : null}
    //         </Paper>
    //       </Paper>
    //     </div>
    //   )}
    // </Downshift>
