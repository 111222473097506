import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import IFrameForm from "../components/common/IFrameForm";

const AddCoffeeShopPage = () => {
  useEffect(() => {
    if (window !== undefined) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "add-coffee-shop-form",
        location: window.location.href,
      });
    }
  }, []);

  return (
    <IFrameForm
      src="https://tally.so/embed/w2NaMV?alignLeft=1&transparentBackground=1&dynamicHeight=1"
      title="Add Your Coffee Shop"
    />
  );
};

export default AddCoffeeShopPage;
