import React, { useState } from 'react';
import ReactGA from "react-ga4";

import {Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Text, Textarea, Button, useToast} from '@chakra-ui/react';
import { useColorMode } from '@chakra-ui/react';

  
const FeedbackModal = ({ isOpen, onClose }) => {
    const { colorMode } = useColorMode();
    const toast = useToast();

    const [feedback, setFeedback] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const bg = colorMode === 'dark' ? '#2c2c2c' : '#fff5ee';
    const textAreaBg = colorMode === 'dark' ? '#262626' : 'gray.50';

    
    const handleFeedbackSubmit = (event) => {
        event.preventDefault();
    
        const myForm = event.target;
        const formData = new FormData(myForm);
        const reqBody = new URLSearchParams(formData).toString()
    
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: reqBody,
        })
        .then((response) => {
         
          if (response.ok) {
            setSubmitted(true);
    
            ReactGA.event({
                category: 'Form',
                action: 'Submit Success',
                label: 'Feedback Form',
            });
            setTimeout(() => {
                        onClose();  // Close the modal after 2 seconds
                        setFeedback('')
                        setSubmitted(false); // Reset submission state for next open
                    }, 2000);
          } else {
            throw new Error('Network response was not ok.');
          }
        })
        .catch((error) => {
          console.error('Submission error', error);
            toast({
                title: 'Form submission failed.',
                description: error.toString(),
                status: 'error',
                duration: 7000,
                isClosable: true,
            });
        });
    };

    return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg={bg}>
            <ModalHeader>Give Feedback</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb={6} as="form" onSubmit={handleFeedbackSubmit} name="feedback" method="POST" data-netlify="true">
                <input type="hidden" name="form-name" value="feedback" />
                {submitted ? <Text>Feedback submitted, thanks!</Text>
                :
                (<>
                    <Textarea name="feedbackMessage" bg={textAreaBg} value={feedback} onChange={(e) => setFeedback(e.target.value)} placeholder="All feedback is good feedback" size="md" _placeholder={{ opacity: 0.4, color: 'inherit' }} focusBorderColor={colorMode === 'dark' ? 'coffee.100' : 'transparent'}/>
                    <Button mt={4} type="submit" isDisabled={!feedback.trim()}>Submit</Button>
                </>
                )}
            </ModalBody>
        </ModalContent>
    </Modal>
  );
};

export default FeedbackModal;