import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

import { Flex, Text, Button, Image } from '@chakra-ui/react';
import { ArrowBackIcon } from "@chakra-ui/icons";

const NotFoundPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window !== undefined) {
    ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "404-not-found",
        location: window.location.href
      });
    }
  }, []);

  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center" px={{ base: "50px", sm: "75px", md: "125px", lg: "175px" }} h="100vh">
      <Text fontSize={{base:'xl',md:'2xl'}} mb="4" textAlign="center"> This page might not exist.</Text>
      <Text fontSize={{base:'xs',md:'md'}} mb="4" textAlign="center"> If you keep getting redirected to this page but are not suppposed to, reach out! </Text>
      <Image
        src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExeWgzd2c3MnIxa29jd2Mzb3FuejVqNmN5MHdkY2QxOXJkMjFzejF1dyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3ohA2ZD9EkeK2AyfdK/giphy.gif"
        alt="Sorry GIF"
        mb="8"
        alignSelf={'center'}
      />
      <Button onClick={() => navigate('/')} leftIcon={<ArrowBackIcon />} variant="solid" borderRadius='20px'>Back to Home</Button>
    </Flex>
  );
};

export default NotFoundPage;