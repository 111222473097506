import { extendTheme } from '@chakra-ui/react';

// Define the colors to use in both light and dark modes
const colors = {
  coffee: {
    50: '#f7f0e8', // Light mode background
    100: '#e6d5c3', // Light mode elements
    200: '#d4ba9d', // Additional accents
    500: '#8B4513', // Primary brand color, dark brown
    800: '#2c1d11', // Dark mode elements
    900: '#1d1308', // Dark mode background
  },
};

// Custom components styles
const components = {
  Button: {
    baseStyle: ({ colorMode }) => ({
      color: colorMode === 'dark' ? 'coffee.50' : 'coffee.800',
    }),
    variants: {
      solid: ({ colorMode }) => ({
        bg: colorMode === 'dark' ? 'coffee.100': 'coffee.500',
        color: colorMode === 'dark' ? 'coffee.800' : 'coffee.50',
        _hover: {
          bg: colorMode === 'dark' ?'coffee.200': 'coffee.600',
        },
      }),
    },
  },
};

// Define the light and dark mode config, Start with system mode
const config = {
  initialColorMode: 'system',
  useSystemColorMode: true,
};

// Extend the theme
const theme = extendTheme({
  colors,
  components,
  config,
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? 'linear-gradient(to right, #3c2f2f, #121212)' : 'linear-gradient(to right, #a89076, #fff5ee)',
        // linear-gradient(to right, #402101, #1f1102)
        color: props.colorMode === 'dark' ? 'coffee.50' : 'coffee.800',
      },
    }),
  },
});

export default theme;