import React, { useState } from "react";

import { Box, Card, CardBody, CardFooter, IconButton, Image, Stack, Heading, Badge, Flex, Icon, Text, Link, Tooltip, useColorModeValue, Collapse, useMediaQuery } from "@chakra-ui/react";
import { MdStar, MdLanguage, MdAccessTime, MdLocationOn, MdDirections} from 'react-icons/md';
import { ExternalLinkIcon } from '@chakra-ui/icons'

import ShareOptions from "../common/ShareOptions";
import coffeePlaceholder from "../../assets/icons/coffee_thumbnail_placeholder.svg";

import ReactGA from 'react-ga4';
import ShopModal from "./ShopModal";

const ShopCard = ({ shop, activeCardId, cardRef }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSmallScreen] = useMediaQuery('(max-width: 48em)');
    
    const boxShadowColor = useColorModeValue('rgba(0, 0, 0, 0.6)', 'rgba(255, 255, 255, 0.6)');
    const bg = useColorModeValue('gray.50', '#2c2c2c');
    
    const toggleExpand = () => {
        if (isSmallScreen) {
            setIsModalOpen(true);
        } else {
            setIsExpanded(!isExpanded);
        }

        ReactGA.event({
            category: 'Button',
            action: 'Click',
            label: 'Expand Card'
        });
    };

    const handleDirectionsClick = (event,shop) => {
        event.stopPropagation();
        ReactGA.event({
          category: 'Button',
          action: 'Click',
          label: `Get Directions - ${shop.name}`,
        });
    
        if (window !== undefined) {
          window.open(shop.google_maps_url, '_blank', 'noopener,noreferrer');
        }
    };

    return (
    <>
    <Box key={shop.id} ref={cardRef} data-shop-id={shop.id} onClick={toggleExpand}>
        <Card borderRadius = "20px" minW = {{base:'250px', sm:'none'}} maxW = "400px" backgroundColor={bg} boxShadow={activeCardId === shop.id ? `0px 4px 10px ${boxShadowColor}` : 'none'} transition="box-shadow 0.3s ease-in-out" cursor="pointer">
            {isExpanded && 
                <Image src={`https://places.googleapis.com/v1/${shop.thumbnail}` || coffeePlaceholder} alt={shop.name} height={{base:'120px', md:'170px'}} objectFit="cover" borderTopRadius={"20px"}  w="100%"/>
            }
            <CardBody pb={3} >
                <Stack spacing='2'>
                    <Flex>
                        <Badge colorScheme={shop.open_now ? 'green' : 'red'} fontSize='0.8em' borderRadius={"20px"}> {shop.open_now ? 'Open Now' : 'Closed'}</Badge>
                    </Flex>
                    <Heading size={{base:'xs', md:'md'}} noOfLines={isExpanded?2:1}>{shop.name}</Heading>
                    <Flex alignItems="center">
                        <Icon as={MdStar} color="yellow.500" />
                        <Text ml={1} fontSize={{base:'xs', md:'sm'}}> {shop.rating ? `${shop.rating} (${shop.user_ratings_total} reviews)` : 'Rating not available'}</Text>
                    </Flex>
                </Stack>
                <Stack spacing='2'>
                    <Collapse in={isExpanded} startingHeight={0}>
                     <Flex alignItems="center" mt="5px">
                            <Icon as={MdLanguage} mr="5px" />
                            {shop.website ? (
                                <Tooltip label={shop.website}>
                                <Link href={shop.website} isExternal display="flex" alignItems="center" fontSize={{base:'xs', md:'sm'}} onClick={(event) => event.stopPropagation()}> Website <ExternalLinkIcon mx='2px' /> </Link>
                                </Tooltip>
                            ) : (
                                <Text fontSize={{base:'xs', md:'sm'}}>Website not available</Text>
                            )}
                        </Flex>
                        <Flex alignItems="center" mt="5px">
                            <Icon as={MdAccessTime} mr="5px" color="text.secondary" />
                            <Text fontSize={{base:'xs', md:'sm'}}>Hours:</Text>
                        </Flex>
                        <Box ml="24px" display="flex" flexDirection="column">
                            {shop.opening_hours ? (
                                shop.opening_hours.map((hours, index) => {
                                let [day, ...timeParts] = hours.split(':'); // ["Monday", " 11", "30 AM – 8", "00 PM"]
                                let time = timeParts.join(':'); // " 11:30 AM – 8:00 PM"
                                return (
                                    <Text key={index} fontSize={{base:'xs', md:'sm'}}>
                                        <span style={{ minWidth: '80px', display: 'inline-block' }}>{day}</span>
                                        <span>{time}</span>
                                    </Text>
                                )})
                            ) : (
                                <Text fontSize={{base:'xs', md:'sm'}}>Opening hours not available</Text>
                            )}
                        </Box>
                        <Flex alignItems="center" mt="5px">
                            <Icon as={MdLocationOn} mr="5px" color="text.secondary" />
                            <Text fontSize={{base:'xs', md:'sm'}}>{shop.address}</Text>
                        </Flex>
                    </Collapse>
                </Stack>
            </CardBody>
            <CardFooter p={{base:"10px", md:"20px"}} display={{ base: 'none', md: 'block' }}>
                <Flex justify="space-between" alignItems="center" w="100%">
                    <Tooltip label="Coming soon - Add to Favorites" hasArrow placement='top'> 
                        <IconButton icon = {<MdStar/>} isDisabled aria-label="add to favorites" borderRadius={"16px"}/>
                    </Tooltip>
                
                    <ShareOptions url={shop.google_maps_url} name={shop.name} title="Check out this local coffee shop: " />

                    <Tooltip label="Directions" hasArrow placement='top'> 
                        <IconButton icon={ <MdDirections />} onClick={(event)=> {handleDirectionsClick(event,shop)}} aria-label="Directions" fontSize={{ base: "20", md: "25" }} borderRadius={"16px"} />                   
                    </Tooltip>
                </Flex>
            </CardFooter>
        </Card>
    </Box>
    <ShopModal shop={shop} isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
    )
};

export default ShopCard;





// const [showFull, setShowFull] = useState(false);

    // const isExpanded = (shopId) => expandedCard === shopId;

    // const handleExpandClick = (shopId) => {
    //     const cardRect = cardRef.current[shopId].getBoundingClientRect();
    //     const viewportHeight = window.innerHeight;
    //     const spaceBelow = viewportHeight - cardRect.bottom;
      
    //     // Assume a desired expansion height, e.g., 300 pixels
    //     const desiredExpansionHeight = 300;
      
    //     if (spaceBelow < desiredExpansionHeight) {
    //       // Not enough space to expand downwards
    //       // You might need to scroll the card into view or adjust its position
    //       window !== undefined && window.scrollBy({
    //         top: cardRect.bottom - viewportHeight + desiredExpansionHeight, // Calculate the offset needed to make space
    //         behavior: 'smooth'
    //       });
    //     }
    //       setExpandedCard(expandedCard === shopId ? null : shopId);
          
    //       ReactGA.event({
    //         category: 'Button',
    //         action: 'Click',
    //         label: 'Expand Card',
    //       });
    //     };
    
    // // Style for the fade effect
    // const fadeStyle = {
    //     position: 'absolute',
    //     bottom: 0, // Height of the expand section
    //     left: '2px', //taking care of 2px outline 
    //     right: '2px', //taking care of 2px outline 
    //     height: '2em',
    //     backgroundImage: 'linear-gradient(to bottom, transparent, white)',
    //     pointerEvents: 'none',
    // };

    // // Style for the expand section
    // const expandSectionStyle = {
    //     textAlign: 'center',
    //     cursor: 'pointer',
    //     padding: '5px 0',
    //     position: 'relative', // Positioned relative to the card content
    // };


    // const activeCardStyle = {
    //     outline: '2px solid #f4a261',
    //     outlineOffset: '-2px', //outline highlight is inward so that card size remains same
    //     transition: 'outline 0.2s ease',
    //     boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.1)', // Keep a subtle shadow
    // };

    // const toggleShowFull = () => {
    //     setShowFull(!showFull);
    // };