import React, { useContext, useEffect } from "react";
import { Stack } from '@chakra-ui/react';

import { CoffeeShopContext } from "../../context/CoffeeShopContext";
import ShopCard from "./ShopCard";


const ListCards = ({cardRefs, activeCardId, resetActiveCard, selectShop}) => {
  const { coffeeShops, loading } = useContext(CoffeeShopContext);

  useEffect(() => {
    if (activeCardId !== null) {
      const timer = setTimeout(() => {
        resetActiveCard();
        // emit an event or call a callback function here to reset the activeCardId in the parent.
      }, 1000); // Reset after 1 second
  
      return () => clearTimeout(timer); // Clear the timer if the component is unmounted or if activeCardId changes before the timer completes.
    }
  }, [activeCardId, resetActiveCard]);


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            selectShop(entry.target.dataset.shopId);
          }
        });
      },
      {
        root: null, // assuming the viewport is the scroll container
        rootMargin: '0px',
        threshold: 0.7, // trigger when at least 50% of the item is visible
      }
    );
  
    // Assuming each card has a ref attached and is stored in `cardRefs`
    Object.values(cardRefs.current).forEach((card) => {
      if (card) observer.observe(card);
    });
  
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      Object.values(cardRefs.current).forEach((card) => {
        if (card) observer.unobserve(card);
      });
    };
  }, [cardRefs, selectShop]);


  return (
  < >
    {loading ? <div style={{display:"flex", justifyContent:"center", alignContent:"center"}}> </div>
    : (
    <Stack direction={{base: 'row', md:'column'}} spacing={3}>
        {coffeeShops && coffeeShops.map((shop) => (
          <ShopCard key={shop.id} shop={shop} activeCardId={activeCardId} cardRef={(el) => (cardRefs.current[shop.id] = el)}/>
        ))}
    </Stack>
  )}
   </>
  );
};

export default ListCards;

// Box
//   key={shop.id}
//   ref={(el) => (cardRefs.current[shop.id] = el)}
//   borderWidth={1}
//   borderRadius="lg"
//   overflow="hidden"
//   boxShadow={isSmallScreen ? 'none' : 'lg'}
//   transition="box-shadow 0.3s"
//   _hover={!isSmallScreen ? { boxShadow: 'xl' } : {}}
//   marginBottom={!isSmallScreen ? 4 : 0}
//   maxH={isExpanded(shop.id) ? '300px' : '200px'}
//   position="relative"
// >

//   <Box padding={4}>
//     <Text fontSize="xl" fontWeight="bold" mb={2} overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap" onClick={toggleShowFull}>
//       {shop.name}
//     </Text>
//     <Flex alignItems="center" mb={2}>
//       <Badge colorScheme={shop.open_now ? 'green' : 'red'} mr={2}>
//         {shop.open_now ? 'Open Now' : 'Closed'}
//       </Badge>
//       <Icon as={StarIcon} color="yellow.500" />
//       <Text ml={1} fontSize="sm">
//         {shop.rating ? `${shop.rating} (${shop.user_ratings_total} reviews)` : 'Rating not available'}
//       </Text>
//     </Flex>
//     {isExpanded(shop.id) && (
//       <>
//         {/* Additional information when expanded */}
//         <Text mb={2}>
//           {shop.address}
//         </Text>
//         <Text mb={2}>
//           Website: <Link href={shop.website} isExternal>{shop.website}</Link>
//         </Text>
//         <Text mb={2}>
//           Hours:
//         </Text>
//         <Grid gap={1} mb={2}>
//           {shop.opening_hours ? (
//             shop.opening_hours.map((hours, index) => (
//               <Text key={index} fontSize="sm">
//                 {hours}
//               </Text>
//             ))
//           ) : (
//             <Text fontSize="sm">Opening hours not available</Text>
//           )}
//         </Grid>
//         {/* Other details and actions */}
//       </>
//     )}
//     {!isExpanded(shop.id) && <Box h={2} bgGradient="linear(to-b, transparent, white)" />}
//     <Button
//       size="sm"
//       colorScheme="blue"
//       onClick={() => handleExpandClick(shop.id)}
//       mt={2}
//     >
//       {isExpanded(shop.id) ? 'Show less' : 'Show more'}
//     </Button>
//   </Box>
// Box>

// <VStack spacing="20px" paddingX="30px">
// {coffeeShops.map((shop, index) => (
//   <Box key={shop.id} width="100%" maxWidth="300px">
//     <Flex direction="column" boxShadow="md" borderRadius="20px">
//       {shop.thumbnail && (
//         <Image src={`https://places.googleapis.com/v1/${shop.thumbnail}`} alt={shop.name} borderRadius="20px 20px 0 0" objectFit="cover" />
//       )}
//       {/* Card Content */}
//     </Flex>
//   </Box>
// ))}
// </VStack>



// {isSmallScreen?(
//   <Box 
//     sx={{
//       overflowX: isSmallScreen ? 'auto' : 'hidden', // Enable horizontal scrolling on small screens
//       whiteSpace: isSmallScreen ? 'nowrap' : 'normal', // Keep items in a line on small screens
//       paddingLeft: "30px",
//       paddingRight: "30px",
//       '::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for WebKit browsers
//       msOverflowStyle : 'none',  // Hide scrollbar for IE, Edge
//       scrollbarWidth: 'none', // Hide scrollbar for Firefox
//       marginBottom:0,
//       paddingBottom: `calc(env(safe-area-inset-bottom) + 30px)`
//     }}
//   >   
//   {coffeeShops && coffeeShops.map((shop, index) => (
//    <Box
//     key={shop.id}
//     data-shop-id={shop.id}
//     ref={(el) => (cardRefs.current[shop.id] = el)}
//     sx={{
//       display: 'inline-block', // Display cards inline for horizontal scrolling
//       width: isSmallScreen ? 'auto' : '100%', // Full width on larger screens, auto on small screens
//       maxWidth: '300px', // Max card width
//       margin: isSmallScreen ? '0 10px' : 'auto', // Margins for spacing between cards on small screens
//       height: 'auto', // Expand height if the card is expanded
//     }}
//     >  
//     <Card key={index} sx={{width:'250px', margin: 'auto', borderRadius: "20px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", ...(shop.id === activeCardId ? activeCardStyle : {}),  maxHeight: isExpanded(shop.id) ? '300px' : '200px', overflow: 'hidden'}} >
//       { isExpanded(shop.id) && shop.thumbnail && 
//         <CardMedia 
//             component="img" 
//             loading="lazy" 
//             alt={shop.name}  
//             height={'120px'} 
//             image={`https://places.googleapis.com/v1/${shop.thumbnail}` || coffeePlaceholder} 
//             sx={{ 
//               borderTopLeftRadius: "16px", 
//               borderTopRightRadius: "16px", 
//               objectFit: shop.thumbnail ? "none":"contain"
//             }}s
//           />
//       }
//       <CardContent sx={{ padding: ["8px 16px", "16px 24px"] ,  position: 'relative', maxHeight: isExpanded(shop.id) ? '90px' : '30px', overflowY: isExpanded(shop.id) ? 'scroll' : 'hidden' }}>
//         <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'  }}>
//           {/* Name of shop */}
//           <Typography component="div"  sx={{ flex: 1, fontSize: '16px', fontWeight: 'bold', marginRight: '15px', minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: isExpanded(shop.id)?'normal':'nowrap' }} onClick={toggleShowFull}>{shop.name}</Typography>
//             {/* Name of shop */}
//           <Typography variant="body2" color="text.secondary" sx={{fontSize:isSmallScreen?"0.4em":"0.6em", color: shop.open_now ? 'green' : 'red', fontWeight:'bold'}}>{shop.open_now ? 'Open Now' : 'Closed'}</Typography>
//         </Box>
//         <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '10px'}}>
//           {/* Rating */}
//           <Rating name="read-only" value={shop.rating} size={isSmallScreen?"small":"medium"} readOnly precision={0.1} />
//           {shop.rating ? (
//           <Typography variant="body2" color="text.secondary" style={{ fontSize:isSmallScreen?"0.8em":"1em", marginLeft: '5px' }}>{shop.rating} ({shop.user_ratings_total} reviews)</Typography>) 
//           :  <Typography variant="body2" color="text.secondary" style={{ fontSize:isSmallScreen?"0.8em":"1em", marginLeft: '5px' }}>Rating not available</Typography>}
//         </div>
//         {isExpanded(shop.id) && (
//             <>
//         {/* Website */}
//         <div style={{ marginTop: '6px', display: 'flex', alignItems: 'center',  }}>
//           {shop.website ? (
//             <Link href={shop.website} color="text.secondary" underline="hover" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
//               <LanguageIcon fontSize="small" sx={{marginRight:"5px", color: "text.secondary"}}/>
//               <Tooltip title={shop.website}>
//                 <Typography variant="body2" color="text.secondary" sx={{fontSize:isSmallScreen?"0.8em":"1em"}}>Website</Typography>
//                 </Tooltip>
//             </Link>
//           ):
//           <>
//           <LanguageIcon fontSize="small" sx={{marginRight:"5px"}}/>
//           <Typography variant="body2" color="text.secondary" sx={{fontSize:isSmallScreen?"0.8em":"1em"}}>Website not available</Typography>
//           </>
//           }
//         </div> 
//         <div style={{ marginTop: '6px', display: 'flex', alignItems: 'center'}}>
//           <AccessTimeIcon fontSize="small" sx={{ marginRight: "5px", color: "text.secondary" }} />
//           <Typography key={index} variant="body2" color="text.secondary"sx={{fontSize:isSmallScreen?"0.8em":"1em"}}>Hours:</Typography>
//         </div>
//         <div style={{ marginTop: '6px', marginLeft:"24px", display: 'flex', flexDirection: 'column' }}>
//           {shop.opening_hours ? (
//             shop.opening_hours.map((hours, index) => {
//               let [day, ...timeParts] = hours.split(':'); // ["Monday", " 11", "30 AM – 8", "00 PM"]
//               let time = timeParts.join(':'); // " 11:30 AM – 8:00 PM"
//               return (
//                 <Typography key={index} variant="body2" color="text.secondary" sx={{ fontSize: isSmallScreen ? "0.8em" : "1em", display: 'flex' }}>
//                   <span style={{ minWidth: '80px', display: 'inline-block' }}>{day}</span>
//                   <span>{time}</span>
//                 </Typography>
//             )})
//           ) : (
//             <Typography variant="body2" color="text.secondary">Opening hours not available</Typography>
//           )}
//         </div> 
//         <div style={{ marginTop: '6px', display: 'flex', alignItems: 'center'}}>
//           <LocationOnIcon fontSize="small" sx={{marginRight: '5px', color: "text.secondary" }} />
//           <Typography key={index} variant="body2" color="text.secondary" sx={{fontSize:isSmallScreen?"0.8em":"1em",  whiteSpace: 'normal', overflowWrap: 'break-word'}}>
//             {shop.address}
//           </Typography>
//         </div>
//         </>
//         )}
//         {!isExpanded(shop.id) && <div style={fadeStyle}></div>}
        
                    
//       </CardContent>
//       <div style={expandSectionStyle} onClick={() => handleExpandClick(shop.id)}>
//         <ExpandMoreIcon fontSize="small" sx={{ verticalAlign: 'middle', transform: isExpanded(shop.id) ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s' }} />
//         <Typography variant="body2" sx={{ display: 'inline', verticalAlign: 'middle', fontSize: isSmallScreen ? "0.8em" : "1em"}}>
//           {isExpanded(shop.id) ? 'Show less' : 'Show more'}
//         </Typography>
//       </div>
//       <CardActions 
//         disableSpacing 
//         sx={{ 
//           justifyContent: "space-between", 
//           padding: "0 10px 8px",
//           '& button': {
//             '&:hover': {
//               backgroundColor: '#f4a261'
//             }},
//             '& .MuiIconButton-root': { // Targeting IconButton specifically
//               padding: '5px', // Reducing padding inside IconButton
//               '& .MuiSvgIcon-root': { // Targeting icons inside IconButton
//                 fontSize: '1.2rem', // Adjust icon size if needed
//               }
//             }
//         }}>
//         <Tooltip title="Coming Soon - Add to Favorites" placement="top">
//           <span onClick={handleFavoritesClick}>
//             <IconButton disabled variant="contained" size="small" aria-label="add to favorites" sx={{ backgroundColor: "#f4a261",color: "#ffffff"}}>
//               <StarIcon />
//             </IconButton> 
//             </span>
//         </Tooltip>
        
//         <ShareOptions url={shop.google_maps_url} name={shop.name} title="Check out this local coffee shop: " />

//         <IconButton variant="contained" size="small" aria-label="directions" sx={{ backgroundColor: "#f4a261",color: "#ffffff"}} onClick={()=> {handleDirectionsClick(shop)}}>
//           <DirectionsIcon/>              
//         </IconButton>
//       </CardActions>
//     </Card>
//   </Box>
//   ))}
// </Box>
// ):(
//   <Grid container spacing={{ xs: 2, md: 2, lg:3 }} style={{ paddingLeft: "30px", paddingRight: "30px"}}>
//   {coffeeShops && coffeeShops.map((shop, index) => (
//     <Grid item xs={12} sm={12} md={12} lg={12} key={shop.id} ref={el => cardRefs.current[shop.id] = el} data-shop-id={shop.id} >
//       <Card key={index} sx={{maxWidth:'400px', margin: 'auto', borderRadius: "20px", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", ...(shop.id === activeCardId ? activeCardStyle : {})}} >
//       { isExpanded(shop.id) && shop.thumbnail && 
//       <CardMedia component="img" loading="lazy" alt={shop.name} height="200" image={`https://places.googleapis.com/v1/${shop.thumbnail}` || coffeePlaceholder}  sx={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px", objectFit: shop.thumbnail ? "none":"contain"}}/>
//       }
//         <CardContent sx={{ padding: ["8px 16px", "16px 24px"] ,  position: 'relative', maxHeight: isExpanded(shop.id) ? 'none' : '150px', overflow: 'hidden' }}>
        
//         <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'  }}>
//           {/* Name of shop */}
//           <Typography component="div"  sx={{ flex: 1, fontSize: '20px', fontWeight: 'bold', marginRight: '15px', minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: isExpanded(shop.id)?'normal':'nowrap' }} onClick={toggleShowFull}>{shop.name}</Typography>
//             {/* Name of shop */}
//           <Typography variant="body2" color="text.secondary" sx={{fontSize:"0.6em", color: shop.open_now ? 'green' : 'red', fontWeight:'bold'}}>{shop.open_now ? 'Open Now' : 'Closed'}</Typography>
//         </Box>

//           <div style={{ display: 'flex', alignItems: 'center' }}>
//           {/* Rating */}
//           <Rating name="read-only" value={shop.rating} size="medium" readOnly precision={0.1} />
//           {shop.rating ? (
//           <Typography variant="body2" color="text.secondary" style={{ marginLeft: '5px' }}>{shop.rating} ({shop.user_ratings_total} reviews)</Typography>) 
//           :  <Typography variant="body2" color="text.secondary" style={{ marginLeft: '5px' }}>Rating not available</Typography>}
//           </div>
//           {/* Website */}
//           <div style={{ marginTop: '6px', display: 'flex', alignItems: 'center',  }}>
//            {shop.website ? (
//               <Link href={shop.website} color="text.secondary" underline="hover" target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center', marginRight: '8px' }}>
//                 <LanguageIcon fontSize="small" sx={{marginRight:"5px", color: "text.secondary"}}/>
//                 <Tooltip title={shop.website}>
//                   <Typography variant="body2" color="text.secondary">Website</Typography>
//                   </Tooltip>

//               </Link>
//             ):
//             <>
//             <LanguageIcon fontSize="small" sx={{marginRight:"5px"}}/>
//             <Typography variant="body2" color="text.secondary">Website not available</Typography>
//             </>
//             }
//           </div> 
//           {isExpanded(shop.id) && (
//             <>
//           <div style={{ marginTop: '6px', display: 'flex', alignItems: 'center'}}>
//             <AccessTimeIcon fontSize="small" sx={{ marginRight: "5px", color: "text.secondary" }} />
//             <Typography key={index} variant="body2" color="text.secondary">Hours:</Typography>
//           </div>
          
//           <div style={{ marginTop: '6px', marginLeft:"24px", display: 'flex', flexDirection: 'column' }}>
//             {shop.opening_hours ? (
//               shop.opening_hours.map((hours, index) => {
//                 let [day, ...timeParts] = hours.split(':'); // ["Monday", " 11", "30 AM – 8", "00 PM"]
//                 let time = timeParts.join(':'); // " 11:30 AM – 8:00 PM"
//                 return (
//                   <Typography key={index} variant="body2" color="text.secondary" sx={{ display: 'flex' }}>
//                     <span style={{ minWidth: '80px', display: 'inline-block' }}>{day}</span>
//                     <span>{time}</span>
//                   </Typography>
//               )})
//             ) : (
//               <Typography variant="body2" color="text.secondary">Opening hours not available</Typography>
//             )}
//           </div> 
//           <div style={{ marginTop: '6px', display: 'flex', alignItems: 'center'}}>
//           <LocationOnIcon fontSize="small" sx={{marginRight: '5px', color: "text.secondary" }} />
//             <Typography key={index} variant="body2" color="text.secondary">{shop.address}</Typography>
//           </div>
//           </>)}
//           {!isExpanded(shop.id) && <div style={fadeStyle}></div>}
         
                     
//         </CardContent>
//         <div style={expandSectionStyle} onClick={() => handleExpandClick(shop.id)}>
//         <ExpandMoreIcon sx={{ verticalAlign: 'middle', transform: isExpanded(shop.id) ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.2s' }} />
//         <Typography variant="body2" sx={{ display: 'inline', verticalAlign: 'middle' }}>
//           {isExpanded(shop.id) ? 'Show less' : 'Show more'}
//         </Typography>
//       </div>
//         <CardActions disableSpacing sx={{ justifyContent: "space-between", padding: "0 24px 16px",'& button': {'&:hover': {backgroundColor: '#f4a261'}}}}>
//         <Tooltip title={"Coming Soon - Add to Favorites"}>
//           <span onClick={handleFavoritesClick}>
//           <IconButton disabled aria-label="add to favorites">
//             <StarIcon />
//           </IconButton> 
//           </span>
//           </Tooltip>
         
//           <ShareOptions url={shop.google_maps_url} name={shop.name} title="Check out this local coffee shop: " />
//           <Tooltip title={"Directions"}>
//           <IconButton variant="contained" aria-label="directions" sx={{ backgroundColor: "#f4a261",color: "#ffffff"}} onClick={()=> {handleDirectionsClick(shop)}}>
//           <DirectionsIcon/>              
//           </IconButton>
//            </Tooltip>

//           {/* <Button variant="contained" size="small" sx={{ backgroundColor: "#f4a261",color: "#ffffff"}}>
//             <Link href={shop.google_maps_url} color="inherit" underline="none" target="_blank" rel="noopener noreferrer" aria-label="Directions">Directions</Link>
//           </Button> */}
//         </CardActions>
//       </Card>
//     </Grid>
//   ))}
// </Grid>
// )}
// </>
// )}
