import React from "react";
import { Box, chakra, shouldForwardProp } from '@chakra-ui/react';
import { motion, isValidMotionProp } from 'framer-motion';
import coffeeBean from '../../assets/images/coffee-bean.png'; // Replace with your actual path

const MotionImage = chakra(motion.img, {
  /**
   * Allow motion props and non-Chakra props to be forwarded.
   */
  shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
});

export default function LoadingAnimation() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
    <MotionImage 
      src={coffeeBean} 
      boxSize="40px" 
      animate={{ rotate: 360 }} 
      transition={{
        duration: 1,
        ease: "linear",
        repeat: Infinity,
        repeatType: "loop",
      }}
      />
  </Box>
  );
}
