/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useCallback, useRef, useEffect } from "react";

import ReactGA from 'react-ga4';

import { Map, AdvancedMarker, useMap, ControlPosition } from "@vis.gl/react-google-maps";
import { Box, Button, useMediaQuery, Text, useColorMode } from '@chakra-ui/react';

import { CoffeeShopContext } from "../../../context/CoffeeShopContext";
import CustomMarkers from "./CustomMarkers";
import LoadingAnimation from "../../common/LoadingAnimation";

import darkModeMapStyle from "../../../styles/mapStyles/darkModeMapStyles";
import lightModeMapStyle from "../../../styles/mapStyles/lightModeMapStyle"

const MapComponent = ({scrollToCard,selectedShopId}) => {
    const map = useMap();
    const { colorMode } = useColorMode();
    const { coffeeShops, loading, userLocation, updateUserLocation } = useContext(CoffeeShopContext);
    const [userCenter, setUserCenter] = useState({ lat: -34.397, lng: 150.644 });
    const [cameraPosition, setCameraPosition] = useState(null);
    const [selectedShop, setSelectedShop] = useState(null);
    const [userMoved, setUserMoved] = useState(false);
    const moveTimeout = useRef(null);
    const isSmallScreen = useMediaQuery("(max-width:450px)");

    const handleCameraChange = useCallback((ev) => {
        // console.log("camera ", ev.map.getBounds());
        setCameraPosition(ev.detail.center)
    });

    const handleDragEnd = useCallback((ev) => {
        // console.log("drag end: ", ev);
        if (moveTimeout.current) {
        clearTimeout(moveTimeout.current);
        }
        moveTimeout.current = setTimeout(() => {
        setUserMoved(true); // Set state to show button after a delay
        }, 300);
    });


    useEffect(() => {
        if(userLocation && !isNaN(userLocation.latitude) && !isNaN(userLocation.longitude)){
        setUserCenter({
            lat: parseFloat(`${userLocation.latitude}`),
            lng: parseFloat(`${userLocation.longitude}`),
        });
    }

    }, [userLocation]);

    // Setting map style
    useEffect(() => {
        if (!map) return;
        const mapStyle = colorMode === 'dark' ? darkModeMapStyle : lightModeMapStyle;

        if (userCenter) {
            const styledMapType = new window.google.maps.StyledMapType(mapStyle);
            map.mapTypes.set("styled_map", styledMapType);
            map.setMapTypeId("styled_map");
        }
    }, [map, userCenter, colorMode]);

    if (!userCenter) {
        if (loading) {
        // If still waiting for userLocation
        return <LoadingAnimation />; 
        } else {
        // If userLocation is not available and not loading
        return <Text variant="h6">User location not available. Please refresh and search using the bar or provide location access!</Text>; // Show message
        }
    }

    
    return (
        <div style={{ width: "100%", height: "100%" }}>
        {userCenter && (
            <Map
                defaultCenter={userCenter}
                defaultZoom={14}
                mapId={process.env.REACT_APP_GOOGLE_MAPS_ID}
                fullscreenControl={false}
                mapTypeControl={false}
                gestureHandling={'greedy'}
                zoomControlOptions={{
                    position:ControlPosition.TOP_LEFT
                }}
                streetViewControlOptions={{
                    position:ControlPosition.TOP_LEFT
                }}
                onCameraChanged={handleCameraChange}
                onDragend={handleDragEnd}
            >
                <AdvancedMarker position={userCenter} />
                <CustomMarkers setSelectedShop={setSelectedShop} loadMarker={loading} shopData={coffeeShops} scrollToCard={scrollToCard} selectedShopId={selectedShopId}/>
                {userMoved && (
                    <Box position="absolute" top="10px" left="50%" transform="translateX(-50%)" zIndex="9">
                        <Button
                            borderRadius="20px"
                            fontSize={isSmallScreen ? "sm" : "md"}
                            onClick={() => {
                                ReactGA.event({
                                    category: 'Button',
                                    action: 'Click',
                                    label: 'Search in this Area',
                                });
                                updateUserLocation({
                                    latitude: cameraPosition.lat,
                                    longitude: cameraPosition.lng,
                                });
                                setUserMoved(false);
                            }}
                        >
                            Search in this area
                        </Button>
                    </Box>
                )}
            </Map>
        )}
        </div>
    )
};

export default MapComponent;