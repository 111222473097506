import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

import { Flex, Tooltip, IconButton, useToast, VStack, Stack, useColorMode, FormControl, FormLabel, Input, Button, Textarea, Box, Heading, Text } from '@chakra-ui/react';
import { ArrowBackIcon } from "@chakra-ui/icons";
import LoadingAnimation from "../components/common/LoadingAnimation";

const ContactPage = () => {
  const navigate = useNavigate();
  const { colorMode } = useColorMode();
  const toast = useToast();
  const [submitted, setSubmitted] = useState(false);
  const inputAreaBg = colorMode === 'dark' ? 'transparent' : 'gray.50';
  const inputFocusBorderColor = colorMode === 'dark' ? 'coffee.100' : 'transparent'

  useEffect(() => {
    if (window !== undefined) {
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: "contact-form",
        location: window.location.href,
      });
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    
    const myForm = event.target;
    const formData = new FormData(myForm);
    const reqBody = new URLSearchParams(formData).toString()

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: reqBody,
    })
    .then((response) => {
     
      if (response.ok) {
        setSubmitted(true);

        ReactGA.event({
            category: 'Form',
            action: 'Submit Success',
            label: 'Contact Form',
        });

        setTimeout(() => navigate('/'), 3000);
      } else {
        throw new Error('Network response was not ok.');
      }
    })
    .catch((error) => {
      console.error('Submission error', error);
        toast({
            title: 'Form submission failed.',
            description: error.toString(),
            status: 'error',
            duration: 7000,
            isClosable: true,
        });
    });
  };

  return (
    <Flex direction="column" height="100vh" overflowY="hidden" pl={{ base: "50px", sm:"75px", md: "125px", lg:"175px"}} pr={{ base: "50px", sm:"75px", md: "125px", lg:"175px"}}>
      <Flex justify="flex-start">
        <Tooltip label="Back to Home" hasArrow placement='auto-start'> 
          <IconButton variant="ghost" aria-label="BackButton" icon={<ArrowBackIcon boxSize="6"/>} onClick={() => navigate("/")} sx={{ml:'7px'}}/>
        </Tooltip>
      </Flex>
      { submitted ? 
        (
          <Box textAlign="center" mt="4">
            <Heading>Thank you for your submission!</Heading>
            <Text mt="2" mb="2">You will be redirected shortly.</Text>
            <LoadingAnimation/>
          </Box>
        ):(
        <VStack
          as="form"
          spacing={4}
          w="full"
          maxW={{ base: "100%", md: "100%" }}
          pl={{ base: "10px", md: "50px"}} 
          pr={{ base: "10px", md: "50px"}}
          onSubmit={handleSubmit}
          data-netlify="true"
          name="contact"
          method="POST"
        >
          <input type="hidden" name="form-name" value="contact" />
          <Stack direction={{ base: 'column', md: 'row' }} spacing={4} w="full">
            <FormControl id="first-name" isRequired>
              <FormLabel>First name</FormLabel>
              <Input name="firstName" placeholder="John" bg={inputAreaBg} focusBorderColor={inputFocusBorderColor}/>
            </FormControl>
            <FormControl id="last-name" isRequired>
              <FormLabel>Last name</FormLabel>
              <Input name="lastName" placeholder="Doe" bg={inputAreaBg} focusBorderColor={inputFocusBorderColor}/>
            </FormControl>
          </Stack>

          <Stack direction={{ base: 'column', md: 'row' }} spacing={4} w="full">
            <FormControl id="phone-number">
              <FormLabel>Phone number (Optional)</FormLabel>
              <Input name="phoneNumber" placeholder="(123) 456-7890" type="tel" bg={inputAreaBg} focusBorderColor={inputFocusBorderColor}/>
            </FormControl>
            <FormControl id="email" isRequired>
              <FormLabel>Email</FormLabel>
              <Input name="email" placeholder="john.doe@example.com" type="email" bg={inputAreaBg} focusBorderColor={inputFocusBorderColor}/>
            </FormControl>
          </Stack>

          <FormControl id="message" isRequired>
            <FormLabel>Message</FormLabel>
            <Textarea name="message" placeholder="Your message here" bg={inputAreaBg} focusBorderColor={inputFocusBorderColor}/>
          </FormControl>

          <Button mt={4} w="full" type="submit">
            Submit
          </Button>
        </VStack>
        )}
    </Flex>
  );
};

export default ContactPage;
