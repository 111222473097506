import React from "react";
import { useNavigate } from "react-router-dom";

import {Flex, Image, Tooltip, IconButton, useColorMode, useDisclosure} from "@chakra-ui/react";
import { EmailIcon, SunIcon, MoonIcon, ChatIcon } from '@chakra-ui/icons';

import FeedbackModal from "../common/FeedbackModal";

const Header = () => {
    const navigate = useNavigate();
    const { colorMode, toggleColorMode } = useColorMode();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const iconSize = { base: "20px", md: "25px" };

    return (
        <>
            <Flex as="nav" position="fixed" w="100%" bg="transparent" p={{ base: '5', md: '8' }} justifyContent="space-between" alignItems="center" zIndex="banner">
                <Image src={colorMode === 'light'? "/light_mode_logo.png" : "/favicon-32x32.png"} alt="Coffee Logo" boxSize={{ base: "35px", md: "50px" }} cursor="pointer" onClick={() => navigate("/")}  _hover={{ transform: 'scale(1.2)' }} transition="transform 0.2s"/>
                <Flex alignItems="center">

                    <Tooltip label={colorMode === 'light' ? "Switch to Dark Mode" : "Switch to Light Mode"} hasArrow placement='auto-start'> 
                        <IconButton icon={colorMode === 'light' ? <MoonIcon boxSize={iconSize} /> : <SunIcon boxSize={iconSize}/>} onClick={toggleColorMode} variant="ghost" aria-label="Toggle dark mode" mr={4}/>
                    </Tooltip>

                    <Tooltip label="Give Feedback" hasArrow placement='auto-start'>
                        <IconButton icon={<ChatIcon boxSize={iconSize} />} onClick={onOpen} variant="ghost" aria-label="Give feedback" mr={4}/>
                    </Tooltip>

                    <Tooltip label="Contact" hasArrow placement='auto-start'> 
                        <IconButton icon={<EmailIcon boxSize={iconSize}/>} onClick={() => navigate("/contact")} variant="ghost" aria-label="Contact" mr={1}/>
                    </Tooltip>
                </Flex>
            </Flex>

            {/* Feedback Modal */}
            <FeedbackModal isOpen={isOpen} onClose={onClose}/>
        </>
    );
};

export default Header;