import React from "react";
import { useNavigate } from "react-router-dom";

import { Flex, Box, IconButton, Tooltip } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";

const IFrameForm = ({src, title}) => {
  const navigate = useNavigate();

  return (
    <Flex direction="column" height="100vh" overflowY="hidden" pl={{ base: "40px", md: "100px"}} pr={{ base: "40px", md: "100px"}}>
      <Flex justify="flex-start">
        <Tooltip label="Back to Home" hasArrow placement='auto-start'> 
          <IconButton variant="ghost" aria-label="BackButton" icon={<ArrowBackIcon boxSize="6"/>} onClick={() => navigate("/")} sx={{ml:'7px'}}/>
        </Tooltip>
      </Flex>
      <Box sx={{ flexGrow: 1, height: 0}}>
        <iframe src={src} loading="lazy" width="100%" height="100%" title={title} style={{ border: "none" }} allowFullScreen></iframe>
      </Box>
    </Flex>
  );
};

export default IFrameForm;
