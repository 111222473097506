import React, {useContext, useEffect} from "react";
import { useMap, AdvancedMarker } from "@vis.gl/react-google-maps";

import { Box, Center, Flex, useColorModeValue } from "@chakra-ui/react";

import LoadingAnimation from "../../common/LoadingAnimation";
import { CoffeeShopContext } from "../../../context/CoffeeShopContext";

function CustomIconMarker({details, selected}) {

  const bg = useColorModeValue('gray.50', 'black');
  const iconStyle = {
    fontSize: selected? "2rem": "1.4rem",
    transition: 'all 0.3s ease-in-out', // Smooth transition for size change
    color: details.open_now ? "none" : "#D3D3D3",
  };

  return (
    <Box position="relative"   >
      {/* Coffee Icon */}
      <Box as="span" style={iconStyle}> ☕ </Box>
      <Flex
        position="absolute"
        top={0}
        left="50%"
        transform="translate(-50%, -100%)"
        backgroundColor={bg}
        borderRadius="20px"
        paddingRight="5px"
        pt={1}
        pb={1}
        alignItems="center"
        justifyContent="center"
        boxShadow={selected ? '0 0 8px rgba(0, 0, 0, 0.5)' : 'none'} // Add shadow if selected
      >
        {/* Rating */}
        <Box ml="5px" mr="5px" p="2px 4px">{details.rating ? details.rating : "N/A"}</Box>
        {/* Open/Close Sign */}
        <Box
          width="10px"
          height="10px"
          borderRadius="50%"
          backgroundColor={details.open_now ? "green" : "red"}
        ></Box>
    </Flex>
    </Box>
  );
}

const CustomMarkers = ({setSelectedShop, loadMarker, shopData, scrollToCard, selectedShopId}) => {
  const map = useMap();
  const { userLocation } = useContext(CoffeeShopContext);

  useEffect(() => {
    if (!map || shopData.length === 0) return;

    const bounds = new window.google.maps.LatLngBounds();
    shopData && shopData.forEach((shop) => {
      if (!isNaN(shop.lat) && !isNaN(shop.lng)) {
        bounds.extend(new window.google.maps.LatLng(shop.lat, shop.lng));
      }
    });
    //Add user location to bounds
    if (userLocation && !isNaN(userLocation.latitude) && !isNaN(userLocation.longitude)) {
      bounds.extend(new window.google.maps.LatLng(userLocation.latitude, userLocation.longitude));
    }

    map && map.fitBounds(bounds);
    map && map.setZoom(map.getZoom() - 1);

  }, [map, shopData, userLocation]);

  if (loadMarker) {
    return (
      <Center marginTop={"20px"}>
        <LoadingAnimation />
      </Center>
    );
  }

  return shopData.map((shop, index) => (
    <AdvancedMarker
      key={`marker-${index}`}
      position={{ lat: shop.lat, lng: shop.lng }}
      onClick={(e) => {
        setSelectedShop(shop);
        scrollToCard(shop.id);
      }}
    >
      <CustomIconMarker
        details={shop}
        selected={selectedShopId && shop.id === selectedShopId}
      />
    </AdvancedMarker>
  ));
};

export default CustomMarkers;
