/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {APIProvider } from "@vis.gl/react-google-maps";
import MapComponent from "./MapComponent";

const GoogleMap = ({ scrollToCard, selectedShopId }) => {
  
  return (
    <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <MapComponent scrollToCard={scrollToCard} selectedShopId={selectedShopId}/>
    </APIProvider>
  );
};

export default GoogleMap;
