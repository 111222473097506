import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Flex, Box, Text, VStack } from '@chakra-ui/react';

import ReactGA from 'react-ga4';

import ListCards from '../components/Cards/ListCards';
import SearchBar from '../components/common/SearchBar';
import GoogleMap from "../components/Maps/Google/GoogleMap";

import { CoffeeShopContext } from "../context/CoffeeShopContext";


const ExplorePage = () => {
    const navigate = useNavigate();
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const {userLocation, updateUserLocation, errorMessage } = useContext(CoffeeShopContext);
    const [locationError, setLocationError] = useState(false);
    
    const [scrollTarget, setScrollTarget] = useState(null);
    const [activeCardId, setActiveCardId] = useState(null);

    const [selectedShopId, setSelectedShopId] = useState(null);

    if (window !== undefined) {
        ReactGA.send({
            hitType: "pageview",
            page: window.location.pathname + window.location.search,
            title: "explore",
            location: window.location.href
          });
    }

    useEffect(() => {
        if (!userLocation) {
            navigate("/");
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLocation]);


    const handleSelectShop = (shopId) => {
        setSelectedShopId(shopId);
    };
    const cardRefs = useRef([]);

    const scrollToCard = (index) => {
        setSelectedTabIndex(1)
        setScrollTarget(index);
    };

    const handlePlaceSelected = (latLng)=> {
        updateUserLocation({latitude: latLng[1],longitude: latLng[0],})
    };

    useEffect(() => {
        if (selectedTabIndex === 1 && scrollTarget !== null && cardRefs.current[scrollTarget]) {
            cardRefs.current[scrollTarget].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
            });
            setActiveCardId(scrollTarget);

            // Reset scrollTarget after scroll is done
            setScrollTarget(null);
           
        }
    }, [selectedTabIndex, scrollTarget, cardRefs]);

    const resetActiveCard = () => {
        setActiveCardId(null);
    }

    return (
        <VStack w="100%" h="100vh" spacing={4} overflow="hidden">
            <Box as="section" textAlign="center">
                <SearchBar onPlaceSelected={handlePlaceSelected} />
                    {errorMessage && (
                        <Text mt="20px" px="20px">
                            {errorMessage === "Network Error" ? "Looks like the coffee filter is broken. Please refresh the page and try again!" : errorMessage}
                        </Text>
                    )}
            </Box>
            {!errorMessage && (
              <Flex direction={{ base: 'column', md: 'row' }} flexGrow={1} overflow="hidden" w="100%" position="relative">
                    <Box flex="1" overflow="hidden" borderRadius={"20px"} minHeight={'100%'} >
                        <GoogleMap scrollToCard={scrollToCard} locationError={locationError} selectedShopId={selectedShopId} />
                    </Box>
                    <Box 
                        position='absolute'
                        top={{ base: 'auto', md: '0' }}
                        bottom={{ base: '0', md: 'auto' }}
                        left={{ base: '0', md: 'auto' }}
                        right="0"
                        maxH={{ base: '70%', md: 'full' }} 
                        w={{ base: '100%', md: '35%', lg: '30%' }}
                        p={3}
                        pb={{base:5, md:3}}
                        overflowY={{ base: 'hidden', md: 'auto' }}
                        overflowX={{ base: 'auto', md: 'hidden' }} 
                        className="custom-scrollbar"
                        zIndex="2"
                    >
                        <ListCards cardRefs={cardRefs} activeCardId={activeCardId} resetActiveCard={resetActiveCard} selectShop={handleSelectShop} />
                    </Box>
                </Flex>
            )}
        </VStack>
    );
};

export default ExplorePage;



        // {/* Mobile */}
        // {isSmallScreen? (
        // <>

        //     <Grid container justifyContent="center" alignItems="center" spacing= {2} style={{ marginTop: '20px', paddingLeft:"5px", paddingRight:'5px'}}>   
        //         <Grid item>
        //             <SearchBar onPlaceSelected={handlePlaceSelected}/>
        //         </Grid>
                
        //     </Grid>
        //     {errorMessage ? (
        //     <div style={{ display: "flex", justifyContent: "center", textAlign: "center", color: "#8B4513", marginTop:"20px", padding:"20px"}}>
        //       <Typography variant="body2">
        //         {errorMessage=== "Network Error" ? "Looks like the coffee filter is broken. Please refresh the page and try again!" : errorMessage}
        //       </Typography>
        //     </div>
        //     ):(<>

        //        <div style={{ position: 'relative', paddingTop: "20px",  height:'100vh', overflow: 'hidden' }}>
        //        {/* Map View */}
        //        <GoogeMap scrollToCard={scrollToCard} locationError={locationError} selectedShopId={selectedShopId}/>
                    
        //        {/* List View Overlay */}
        //        <div style={{
        //          position: 'absolute',
        //          bottom: '30px',
        //          left: 0,
        //          right: 0,
        //          maxHeight: '80%', // Adjust as needed
        //          overflowY: 'hidden', // Allows scrolling within the list
        //          backgroundColor: 'transparent', // Semi-transparent background
        //         }}>
        //          <ListCards cardRefs={cardRefs} activeCardId={activeCardId} resetActiveCard={resetActiveCard} selectShop={handleSelectShop} />
        //        </div>
        //      </div>
        //     </>)}
        // </>
        // ) 
        // :isDesktop ? (
        // <>
        //  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
        //     <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100%', marginBottom:'20px'}}>
        //         <Grid container justifyContent="center" sx={{textAlign:"center"}}>
                    
        //             <Grid item xs={12} sm={12} md={12} lg={12}>
        //                 <SearchBar onPlaceSelected={handlePlaceSelected}/>
        //             </Grid>
        //         </Grid>
        //     </Box> 
        //     </div>
        //     <Box sx={{ position: 'relative', height: '100vh', overflow: "hidden" }}>

        //     <GoogeMap scrollToCard={scrollToCard} locationError={locationError} selectedShopId={selectedShopId}/>

        //     <Box sx={{
        //         position: 'absolute',
        //         top: 0,
        //         right: 0,
        //         height: '100%',
        //         width: { md: '37%', lg: '25%', xl: '25%' }, // Adjust width percentages to match your Grid item md/lg/xl values
        //         overflowY: 'auto',
        //         zIndex: 2, 
        //         marginTop: 1
        //     }}>
        //         <ListCards cardRefs={cardRefs} activeCardId={activeCardId} resetActiveCard={resetActiveCard} selectShop={handleSelectShop}/>
        //     </Box>
        // </Box>
        // </>)
        // :(
        // <>
        //     <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: 'auto' , transition: 'top 1s ease-in-out' }}>
        //         <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative', width: '100%'}}>
        //             <Grid container justifyContent="center" sx={{textAlign:"center"}}>
                       
        //                 <Grid item xs={12} sm={12} md={12} lg={12}>
        //                     <SearchBar onPlaceSelected={handlePlaceSelected}/>
        //                 </Grid>
        //             </Grid>
        //         </Box> 
        //     </div>

        //     {errorMessage ? (
        //         <div style={{ display: "flex", justifyContent: "center", textAlign: "center", color: "#8B4513", marginTop:"20px", padding:"20px"}}>
        //             <Typography variant="body2">
        //                 {errorMessage=== "Network Error" ? "Looks like the coffee filter is broken. Please refresh the page and try again!" : errorMessage}
        //             </Typography>
        //         </div>
        //     ):(
        //     <>
        //             <div style={{ display: "flex",  justifyContent: 'center',marginTop: '20px'}}>
        //                 <div style ={{display: 'inline-flex'}}>
        //                     <AppBar position="static" sx={{ backgroundColor: "transparent", boxShadow: "none" }}>
        //                         <Box sx={{ borderRadius: '8px', overflow: 'hidden' }}>
        //                             <Tabs value={selectedTabIndex} onChange={handleChange}  TabIndicatorProps={{style: {backgroundColor: "transparent"}}}>
        //                                 <Tab label="Map" sx={{borderTopLeftRadius:'12px', borderBottomLeftRadius:'12px', border: '2px solid #8B4513', backgroundColor: selectedTabIndex === 0 ? '#8B4513':'transparent', color: selectedTabIndex === 0 ? '#ffffff':'#8B4513', '&.Mui-selected': {color: '#ffffff'}}}/>
        //                                 <Tab label="List" sx={{borderTopRightRadius:'12px', borderBottomRightRadius:'12px', border: '2px solid #8B4513', backgroundColor: selectedTabIndex === 1 ? '#8B4513':'transparent', color: selectedTabIndex === 1 ? '#ffffff':'#8B4513', '&.Mui-selected': {color: '#ffffff'}}}/>
        //                             </Tabs>
        //                         </Box>
        //                     </AppBar>
        //                 </div>
        //             </div>

        //             {/* Map View */}
        //            {selectedTabIndex === 0 && 
        //             <div style={{ paddingTop: "20px", flex: '1 1 auto', overflow: 'hidden', height:'100vh' }}>    
        //             <GoogeMap scrollToCard={scrollToCard} locationError={locationError} selectedShopId={selectedShopId}/>
        //             {/* <MapboxMap scrollToCard={scrollToCard} locationError={locationError} selectedShopId={selectedShopId}/> */}
        //             </div>
        //             }
                   

        //             {/* List View */}
                   
        //             {selectedTabIndex === 1 && 
        //              <div style={{ marginTop: "20px", flex: '1 1 auto', overflowY: 'auto'}}>
        //             <ListCards cardRefs={cardRefs} activeCardId={activeCardId} resetActiveCard={resetActiveCard} selectShop={handleSelectShop}/>
        //             </div>}
        //     </>)}
        // </>
        // )}

