import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Flex, useBreakpointValue } from "@chakra-ui/react";

import HomePage from "./pages/HomePage";
import ExplorePage from "./pages/ExplorePage";
import ContactPage from "./pages/ContactPage";
import AddCoffeeShopPage from "./pages/AddCoffeeShopPage";
import NotFoundPage from "./pages/NotFoundPage";

import Header from "./components/layout/Header";

function App() {
  const paddingTop = useBreakpointValue({ base: '74px', md: '108px' });

  return (
    <Router>
      <Flex direction="column" height="100vh">
        <Header/>
          <Flex direction="column" pt={paddingTop} overflowY="auto">
           <Routes>
              <Route path="/" element={<HomePage />} /> 
              <Route path="/explore" element={<ExplorePage/>} /> 
              <Route path="/contact" element={<ContactPage/>} /> 
              <Route path="/add-coffee-shop" element={<AddCoffeeShopPage />} />
              <Route path="*" element={<NotFoundPage/>} />

            </Routes>
        </Flex>
      </Flex>
    </Router>

  );
}

export default App;
