import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@chakra-ui/react";
import { MdOutlineMyLocation } from "react-icons/md";


import LoadingAnimation from "./LoadingAnimation";
import ReactGA from 'react-ga4';
import { CoffeeShopContext } from "../../context/CoffeeShopContext";

const FindNearMeButton = () => {
  const navigate = useNavigate();
  const { updateUserLocation } = useContext(CoffeeShopContext);
  const [loadingLocation, setLoadingLocation] = useState(false);


  const handleFindNearMe = () => {
    ReactGA.event({
        category: 'Button',
        action: 'Click',
        label: 'Find Near Me',
      });
    setLoadingLocation(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          updateUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setLoadingLocation(false);
          navigate("/explore"); // Navigate to the results page
        },
        (error) => {
          setLoadingLocation(false);
          console.error(error);
          alert(
            "You have not provided location access. Provide access or use the Search bar!"
          );
        }
      );
    } else {
      setLoadingLocation(false);
      console.log("GeoLocation is not supported by this browser.");
    }
  };

  return loadingLocation ? (
        <LoadingAnimation />
      ) : (
        <Button onClick={handleFindNearMe} leftIcon={<MdOutlineMyLocation />} variant="solid" borderRadius='20px'>
          Find Near Me
        </Button>
      )
};

export default FindNearMeButton;
