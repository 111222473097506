import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactGA from 'react-ga4';

import { Flex, VStack, Text, useBreakpointValue } from '@chakra-ui/react';

import SearchBar from '../components/common/SearchBar';
import FindNearMeButton from "../components/common/FindNearMeButton";
import { CoffeeShopContext } from "../context/CoffeeShopContext";

const HomePage = () => {
    const navigate = useNavigate();
    const { updateUserLocation } = useContext(CoffeeShopContext);

    const fontSize = useBreakpointValue({ base: "xl", sm:'2xl', md: "4xl" });

    useEffect(() => {
        if (window !== undefined) {
            ReactGA.send({hitType: "pageview", page: window.location.pathname + window.location.search, title: "home", location: window.location.href});
        }
      }, []);

    return (
        <Flex direction="column" justify="center" alignItems="center" height="100vh" textAlign="center" px={4}>
            <Text fontSize={fontSize} fontWeight="bold" mb={5} fontFamily="heading" sx={{ display: "inline-block",whiteSpace: "nowrap", overflow: "hidden", animation: `typing 3.5s steps(45, end) forwards`, maxWidth: "100%"}}>
                Discover local coffee shops near you
            </Text>
            <VStack spacing={6} marginTop={10} width="full" maxW="md">
                <SearchBar onPlaceSelected={(latLng) => {updateUserLocation({latitude: latLng[1],longitude: latLng[0]}); navigate("/explore");}}/>
                <FindNearMeButton/>
            </VStack>
        </Flex>
    );
};

export default HomePage;